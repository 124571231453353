import React, { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar';

import Eth from '../assets/images/eth.png';

import { onRefrech } from '../config/loginConfig';
import { chambersRef } from '../config/firebaseConfig';
import { onValue } from 'firebase/database';

import { FaRegSnowflake } from 'react-icons/fa';
import { GiOrange } from 'react-icons/gi';
import { WiHumidity } from 'react-icons/wi';
import { PiFanLight } from 'react-icons/pi';
import { FaDoorClosed, FaDoorOpen } from 'react-icons/fa';

const Chambres = () => {
    // ============================================================================== //
    const [ch1, setCh1] = useState([]);
    const [ch2, setCh2] = useState([]);
    const [ch3, setCh3] = useState([]);
    const [ch4, setCh4] = useState([]);
    const [ch5, setCh5] = useState([]);
    const [ch6, setCh6] = useState([]);
    const [ch7, setCh7] = useState([]);
    const [ch8, setCh8] = useState([]);

    useEffect(() => {
        const unsubscribe = onValue(chambersRef, (snapshot) => {
            const data = snapshot.val();
            // ...
            setCh1(data?.chamber1);
            setCh2(data?.chamber2);
            setCh3(data?.chamber3);
            setCh4(data?.chamber4);
            setCh5(data?.chamber5);
            setCh6(data?.chamber6);
            setCh7(data?.chamber7);
            setCh8(data?.chamber8);
        });

        // Cleanup function to unsubscribe from the listener when the component unmounts
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const CheckRefresh = async () => {
            const id = await localStorage.getItem('id');
            await onRefrech(id);
        }
        CheckRefresh();
    }, []);

    // ============================================================================== //

    return (
        <div className="flex lg:gap-5 bg-[#fffcf6]">
            <Sidebar />
            <main className="flex-1 mx-auto py-4 pr-4 h-screen overflow-y-scroll">
                <p className='text-4xl lg:text-5xl font-bold mb-4'>Chambres</p>

                {/* Cards */}
                <div className='mt-8 flex flex-wrap'>

                    {/* Card 01 */}
                    <div className='w-full lg:w-1/2 py-2 lg:p-2'>
                        <div className='bg-white p-4 rounded-lg shadow-md'>
                            <p className='text-3xl lg:text-4xl font-bold text-center border-b-2 border-primary pb-4'>Chambre 01</p>
                            <div>
                                <div className='mt-4 flex justify-between items-center'>
                                    <div className='w-4/5 pr-2 lg:pr-4 flex justify-between items-center'>
                                        <div>
                                            <p className='text-sm'>Température</p>
                                            <p className='text-sm mt-4'>Humidité</p>
                                            <p className='text-sm mt-4'>CO<sub>2</sub></p>
                                            <p className='text-sm mt-4'>Éthylène</p>
                                        </div>

                                        <div>
                                            <p className='text-lg lg:text-xl font-semibold'>{ch1?.data?.temp} <span className='text-sm'><sup>o</sup>C</span></p>
                                            <p className='text-lg lg:text-xl font-semibold mt-2 lg:mt-3'>{ch1?.data?.hum} <span className='text-sm'>%</span></p>
                                            <p className='text-lg lg:text-xl font-semibold mt-2 lg:mt-3'>{ch1?.data?.co2} <span className='text-sm'>ppm</span></p>
                                            <p className='text-lg lg:text-xl font-semibold mt-2 lg:mt-3'>{ch1?.data?.eth} <span className='text-sm'>ppm</span></p>
                                        </div>

                                        <div>
                                            <p className='text-sm lg:text-base text-gray'>{ch1?.consignes?.tempconsigne === '-' ? ch1?.consignes?.tempconsigne : ch1?.consignes?.tempconsigne + '*'}</p>
                                            <p className='text-sm lg:text-base text-gray mt-4'>{ch1?.consignes?.humconsigne === '-' ? ch1?.consignes?.humconsigne : ch1?.consignes?.humconsigne + '*'}</p>
                                            <p className='text-sm lg:text-base text-gray mt-4'>{ch1?.consignes?.co2consigne === '-' ? ch1?.consignes?.co2consigne : ch1?.consignes?.co2consigne + '*'}</p>
                                            <p className='text-sm lg:text-base text-gray mt-4'>{ch1?.consignes?.ethconsigne === '-' ? ch1?.consignes?.ethconsigne : ch1?.consignes?.ethconsigne + '*'}</p>
                                        </div>
                                    </div>

                                    <div className='w-1/5'>
                                        <div className='p-1'>
                                            <div className={`${ch1?.mode?.conservsign === "on" ? "bg-primary" : "bg-lightgray"} rounded-md p-2 flex items-center justify-center`}><FaRegSnowflake color='white' size={32} /></div>
                                        </div>
                                        <div className='p-1'>
                                            <div className={`${ch1?.mode?.deversign === "on" ? "bg-primary" : "bg-lightgray"} rounded-md p-2 flex items-center justify-center`}><GiOrange color='white' size={32} /></div>
                                        </div>
                                        <div className='p-1'>
                                            <div className={`${ch1?.mode?.Forcage === "on" ? "bg-primary" : "bg-lightgray"} rounded-md p-2 flex items-center justify-center text-white font-bold text-2xl`}>F</div>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex justify-between'>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch1?.status?.humsign === "on" ? 'bg-primary' : 'bg-lightgray'} h-14 rounded-md flex items-center justify-center`}><WiHumidity color='white' size={35} /></div>
                                    </div>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch1?.status?.faninsign === "on" ? 'bg-primary' : 'bg-lightgray'} flex flex-col h-14 rounded-md flex items-center justify-center`}>
                                            <PiFanLight color='white' size={35} />
                                            <p className='text-white text-sm'>In</p>
                                        </div>
                                    </div>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch1?.status?.fanoutsign === "on" ? 'bg-primary' : 'bg-lightgray'} flex flex-col h-14 rounded-md flex items-center justify-center`}>
                                            <PiFanLight color='white' size={35} />
                                            <p className='text-white text-sm'>Out</p>
                                        </div>
                                    </div>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch1?.status?.doorsign === "on" ? 'bg-primary' : 'bg-lightgray'} h-14 rounded-md flex items-center justify-center`}>
                                            {
                                                ch1?.status?.doorsign === "on" ? (
                                                    <FaDoorOpen color='white' size={35} />
                                                ) : (
                                                    <FaDoorClosed color='white' size={35} />
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch1?.status?.ethsign === "on" ? 'bg-primary' : 'bg-lightgray'} h-14 rounded-md flex items-center justify-center`}><img src={Eth} alt="" width={30} /></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* Card 02 */}
                    <div className='w-full lg:w-1/2 py-2 lg:p-2'>
                        <div className='bg-white p-4 rounded-lg shadow-md'>
                            <p className='text-3xl lg:text-4xl font-bold text-center border-b-2 border-primary pb-4'>Chambre 02</p>
                            <div>
                                <div className='mt-4 flex justify-between items-center'>
                                    <div className='w-4/5 pr-4 flex justify-between items-center'>
                                        <div>
                                            <p className='text-sm'>Température</p>
                                            <p className='text-sm mt-4'>Humidité</p>
                                            <p className='text-sm mt-4'>CO<sub>2</sub></p>
                                            <p className='text-sm mt-4'>Éthylène</p>
                                        </div>

                                        <div>
                                            <p className='text-lg lg:text-xl font-semibold'>{ch2?.data?.temp} <span className='text-sm'><sup>o</sup>C</span></p>
                                            <p className='text-lg lg:text-xl font-semibold mt-3'>{ch2?.data?.hum} <span className='text-sm'>%</span></p>
                                            <p className='text-lg lg:text-xl font-semibold mt-3'>{ch2?.data?.co2} <span className='text-sm'>ppm</span></p>
                                            <p className='text-lg lg:text-xl font-semibold mt-3'>{ch2?.data?.eth} <span className='text-sm'>ppm</span></p>
                                        </div>

                                        <div>
                                            <p className='text-sm lg:text-base text-gray '>{ch2?.consignes?.tempconsigne === '-' ? ch2?.consignes?.tempconsigne : ch2?.consignes?.tempconsigne + '*'}</p>
                                            <p className='text-sm lg:text-base text-gray mt-4'>{ch2?.consignes?.humconsigne === '-' ? ch2?.consignes?.humconsigne : ch2?.consignes?.humconsigne + '*'}</p>
                                            <p className='text-sm lg:text-base text-gray mt-4'>{ch2?.consignes?.co2consigne === '-' ? ch2?.consignes?.co2consigne : ch2?.consignes?.co2consigne + '*'}</p>
                                            <p className='text-sm lg:text-base text-gray mt-4'>{ch2?.consignes?.ethconsigne === '-' ? ch2?.consignes?.ethconsigne : ch2?.consignes?.ethconsigne + '*'}</p>
                                        </div>
                                    </div>

                                    <div className='w-1/5'>
                                        <div className='p-1'>
                                            <div className={`${ch2?.mode?.conservsign === "on" ? "bg-primary" : "bg-lightgray"} rounded-md p-2 flex items-center justify-center`}><FaRegSnowflake color='white' size={32} /></div>
                                        </div>
                                        <div className='p-1'>
                                            <div className={`${ch2?.mode?.deversign === "on" ? "bg-primary" : "bg-lightgray"} rounded-md p-2 flex items-center justify-center`}><GiOrange color='white' size={32} /></div>
                                        </div>
                                        <div className='p-1'>
                                            <div className={`${ch2?.mode?.Forcage === "on" ? "bg-primary" : "bg-lightgray"} rounded-md p-2 flex items-center justify-center text-white font-bold text-2xl`}>F</div>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex justify-between'>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch2?.status?.humsign === "on" ? 'bg-primary' : 'bg-lightgray'} h-14 rounded-md flex items-center justify-center`}><WiHumidity color='white' size={35} /></div>
                                    </div>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch2?.status?.faninsign === "on" ? 'bg-primary' : 'bg-lightgray'} flex flex-col h-14 rounded-md flex items-center justify-center`}>
                                            <PiFanLight color='white' size={35} />
                                            <p className='text-white text-sm'>In</p>
                                        </div>
                                    </div>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch2?.status?.fanoutsign === "on" ? 'bg-primary' : 'bg-lightgray'} flex flex-col h-14 rounded-md flex items-center justify-center`}>
                                            <PiFanLight color='white' size={35} />
                                            <p className='text-white text-sm'>Out</p>
                                        </div>
                                    </div>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch2?.status?.doorsign === "on" ? 'bg-primary' : 'bg-lightgray'} h-14 rounded-md flex items-center justify-center`}>
                                            {
                                                ch2?.status?.doorsign === "on" ? (
                                                    <FaDoorOpen color='white' size={35} />
                                                ) : (
                                                    <FaDoorClosed color='white' size={35} />
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch2?.status?.ethsign === "on" ? 'bg-primary' : 'bg-lightgray'} h-14 rounded-md flex items-center justify-center`}><img src={Eth} alt="" width={30} /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Card 03 */}
                    <div className='w-full lg:w-1/2 py-2 lg:p-2'>
                        <div className='bg-white p-4 rounded-lg shadow-md'>
                            <p className='text-3xl lg:text-4xl font-bold text-center border-b-2 border-primary pb-4'>Chambre 03</p>
                            <div>
                                <div className='mt-4 flex justify-between items-center'>
                                    <div className='w-4/5 pr-2 lg:pr-4 flex justify-between items-center'>
                                        <div>
                                            <p className='text-sm'>Température</p>
                                            <p className='text-sm mt-4'>Humidité</p>
                                            <p className='text-sm mt-4'>CO<sub>2</sub></p>
                                            <p className='text-sm mt-4'>Éthylène</p>
                                        </div>

                                        <div>
                                            <p className='text-lg lg:text-xl font-semibold'>{ch3?.data?.temp} <span className='text-sm'><sup>o</sup>C</span></p>
                                            <p className='text-lg lg:text-xl font-semibold mt-2 lg:mt-3'>{ch3?.data?.hum} <span className='text-sm'>%</span></p>
                                            <p className='text-lg lg:text-xl font-semibold mt-2 lg:mt-3'>{ch3?.data?.co2} <span className='text-sm'>ppm</span></p>
                                            <p className='text-lg lg:text-xl font-semibold mt-2 lg:mt-3'>{ch3?.data?.eth} <span className='text-sm'>ppm</span></p>
                                        </div>

                                        <div>
                                            <p className='text-sm lg:text-base text-gray'>{ch3?.consignes?.tempconsigne === '-' ? ch3?.consignes?.tempconsigne : ch3?.consignes?.tempconsigne + '*'}</p>
                                            <p className='text-sm lg:text-base text-gray mt-4'>{ch3?.consignes?.humconsigne === '-' ? ch3?.consignes?.humconsigne : ch3?.consignes?.humconsigne + '*'}</p>
                                            <p className='text-sm lg:text-base text-gray mt-4'>{ch3?.consignes?.co2consigne === '-' ? ch3?.consignes?.co2consigne : ch3?.consignes?.co2consigne + '*'}</p>
                                            <p className='text-sm lg:text-base text-gray mt-4'>{ch3?.consignes?.ethconsigne === '-' ? ch3?.consignes?.ethconsigne : ch3?.consignes?.ethconsigne + '*'}</p>
                                        </div>
                                    </div>

                                    <div className='w-1/5'>
                                        <div className='p-1'>
                                            <div className={`${ch3?.mode?.conservsign === "on" ? "bg-primary" : "bg-lightgray"} rounded-md p-2 flex items-center justify-center`}><FaRegSnowflake color='white' size={32} /></div>
                                        </div>
                                        <div className='p-1'>
                                            <div className={`${ch3?.mode?.deversign === "on" ? "bg-primary" : "bg-lightgray"} rounded-md p-2 flex items-center justify-center`}><GiOrange color='white' size={32} /></div>
                                        </div>
                                        <div className='p-1'>
                                            <div className={`${ch3?.mode?.Forcage === "on" ? "bg-primary" : "bg-lightgray"} rounded-md p-2 flex items-center justify-center text-white font-bold text-2xl`}>F</div>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex justify-between'>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch3?.status?.humsign === "on" ? 'bg-primary' : 'bg-lightgray'} h-14 rounded-md flex items-center justify-center`}><WiHumidity color='white' size={35} /></div>
                                    </div>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch3?.status?.faninsign === "on" ? 'bg-primary' : 'bg-lightgray'} flex flex-col h-14 rounded-md flex items-center justify-center`}>
                                            <PiFanLight color='white' size={35} />
                                            <p className='text-white text-sm'>In</p>
                                        </div>
                                    </div>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch3?.status?.fanoutsign === "on" ? 'bg-primary' : 'bg-lightgray'} flex flex-col h-14 rounded-md flex items-center justify-center`}>
                                            <PiFanLight color='white' size={35} />
                                            <p className='text-white text-sm'>Out</p>
                                        </div>
                                    </div>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch3?.status?.doorsign === "on" ? 'bg-primary' : 'bg-lightgray'} h-14 rounded-md flex items-center justify-center`}>
                                            {
                                                ch3?.status?.doorsign === "on" ? (
                                                    <FaDoorOpen color='white' size={35} />
                                                ) : (
                                                    <FaDoorClosed color='white' size={35} />
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch3?.status?.ethsign === "on" ? 'bg-primary' : 'bg-lightgray'} h-14 rounded-md flex items-center justify-center`}><img src={Eth} alt="" width={30} /></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* Card 04 */}
                    <div className='w-full lg:w-1/2 py-2 lg:p-2'>
                        <div className='bg-white p-4 rounded-lg shadow-md'>
                            <p className='text-3xl lg:text-4xl font-bold text-center border-b-2 border-primary pb-4'>Chambre 04</p>
                            <div>
                                <div className='mt-4 flex justify-between items-center'>
                                    <div className='w-4/5 pr-4 flex justify-between items-center'>
                                        <div>
                                            <p className='text-sm'>Température</p>
                                            <p className='text-sm mt-4'>Humidité</p>
                                            <p className='text-sm mt-4'>CO<sub>2</sub></p>
                                            <p className='text-sm mt-4'>Éthylène</p>
                                        </div>

                                        <div>
                                            <p className='text-lg lg:text-xl font-semibold'>{ch4?.data?.temp} <span className='text-sm'><sup>o</sup>C</span></p>
                                            <p className='text-lg lg:text-xl font-semibold mt-3'>{ch4?.data?.hum} <span className='text-sm'>%</span></p>
                                            <p className='text-lg lg:text-xl font-semibold mt-3'>{ch4?.data?.co2} <span className='text-sm'>ppm</span></p>
                                            <p className='text-lg lg:text-xl font-semibold mt-3'>{ch4?.data?.eth} <span className='text-sm'>ppm</span></p>
                                        </div>

                                        <div>
                                            <p className='text-sm lg:text-base text-gray '>{ch4?.consignes?.tempconsigne === '-' ? ch4?.consignes?.tempconsigne : ch4?.consignes?.tempconsigne + '*'}</p>
                                            <p className='text-sm lg:text-base text-gray mt-4'>{ch4?.consignes?.humconsigne === '-' ? ch4?.consignes?.humconsigne : ch4?.consignes?.humconsigne + '*'}</p>
                                            <p className='text-sm lg:text-base text-gray mt-4'>{ch4?.consignes?.co2consigne === '-' ? ch4?.consignes?.co2consigne : ch4?.consignes?.co2consigne + '*'}</p>
                                            <p className='text-sm lg:text-base text-gray mt-4'>{ch4?.consignes?.ethconsigne === '-' ? ch4?.consignes?.ethconsigne : ch4?.consignes?.ethconsigne + '*'}</p>
                                        </div>
                                    </div>

                                    <div className='w-1/5'>
                                        <div className='p-1'>
                                            <div className={`${ch4?.mode?.conservsign === "on" ? "bg-primary" : "bg-lightgray"} rounded-md p-2 flex items-center justify-center`}><FaRegSnowflake color='white' size={32} /></div>
                                        </div>
                                        <div className='p-1'>
                                            <div className={`${ch4?.mode?.deversign === "on" ? "bg-primary" : "bg-lightgray"} rounded-md p-2 flex items-center justify-center`}><GiOrange color='white' size={32} /></div>
                                        </div>
                                        <div className='p-1'>
                                            <div className={`${ch4?.mode?.Forcage === "on" ? "bg-primary" : "bg-lightgray"} rounded-md p-2 flex items-center justify-center text-white font-bold text-2xl`}>F</div>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex justify-between'>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch4?.status?.humsign === "on" ? 'bg-primary' : 'bg-lightgray'} h-14 rounded-md flex items-center justify-center`}><WiHumidity color='white' size={35} /></div>
                                    </div>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch4?.status?.faninsign === "on" ? 'bg-primary' : 'bg-lightgray'} flex flex-col h-14 rounded-md flex items-center justify-center`}>
                                            <PiFanLight color='white' size={35} />
                                            <p className='text-white text-sm'>In</p>
                                        </div>
                                    </div>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch4?.status?.fanoutsign === "on" ? 'bg-primary' : 'bg-lightgray'} flex flex-col h-14 rounded-md flex items-center justify-center`}>
                                            <PiFanLight color='white' size={35} />
                                            <p className='text-white text-sm'>Out</p>
                                        </div>
                                    </div>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch4?.status?.doorsign === "on" ? 'bg-primary' : 'bg-lightgray'} h-14 rounded-md flex items-center justify-center`}>
                                            {
                                                ch4?.status?.doorsign === "on" ? (
                                                    <FaDoorOpen color='white' size={35} />
                                                ) : (
                                                    <FaDoorClosed color='white' size={35} />
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch4?.status?.ethsign === "on" ? 'bg-primary' : 'bg-lightgray'} h-14 rounded-md flex items-center justify-center`}><img src={Eth} alt="" width={30} /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Card 05 */}
                    <div className='w-full lg:w-1/2 py-2 lg:p-2'>
                        <div className='bg-white p-4 rounded-lg shadow-md'>
                            <p className='text-3xl lg:text-4xl font-bold text-center border-b-2 border-primary pb-4'>Chambre 05</p>
                            <div>
                                <div className='mt-4 flex justify-between items-center'>
                                    <div className='w-4/5 pr-4 flex justify-between items-center'>
                                        <div>
                                            <p className='text-sm'>Température</p>
                                            <p className='text-sm mt-4'>Humidité</p>
                                            <p className='text-sm mt-4'>CO<sub>2</sub></p>
                                            <p className='text-sm mt-4'>Éthylène</p>
                                        </div>

                                        <div>
                                            <p className='text-lg lg:text-xl font-semibold'>{ch5?.data?.temp} <span className='text-sm'><sup>o</sup>C</span></p>
                                            <p className='text-lg lg:text-xl font-semibold mt-3'>{ch5?.data?.hum} <span className='text-sm'>%</span></p>
                                            <p className='text-lg lg:text-xl font-semibold mt-3'>{ch5?.data?.co2} <span className='text-sm'>ppm</span></p>
                                            <p className='text-lg lg:text-xl font-semibold mt-3'>{ch5?.data?.eth} <span className='text-sm'>ppm</span></p>
                                        </div>

                                        <div>
                                            <p className='text-sm lg:text-base text-gray '>{ch5?.consignes?.tempconsigne === '-' ? ch5?.consignes?.tempconsigne : ch5?.consignes?.tempconsigne + '*'}</p>
                                            <p className='text-sm lg:text-base text-gray mt-4'>{ch5?.consignes?.humconsigne === '-' ? ch5?.consignes?.humconsigne : ch5?.consignes?.humconsigne + '*'}</p>
                                            <p className='text-sm lg:text-base text-gray mt-4'>{ch5?.consignes?.co2consigne === '-' ? ch5?.consignes?.co2consigne : ch5?.consignes?.co2consigne + '*'}</p>
                                            <p className='text-sm lg:text-base text-gray mt-4'>{ch5?.consignes?.ethconsigne === '-' ? ch5?.consignes?.ethconsigne : ch5?.consignes?.ethconsigne + '*'}</p>
                                        </div>
                                    </div>

                                    <div className='w-1/5'>
                                        <div className='p-1'>
                                            <div className={`${ch5?.mode?.conservsign === "on" ? "bg-primary" : "bg-lightgray"} rounded-md p-2 flex items-center justify-center`}><FaRegSnowflake color='white' size={32} /></div>
                                        </div>
                                        <div className='p-1'>
                                            <div className={`${ch5?.mode?.deversign === "on" ? "bg-primary" : "bg-lightgray"} rounded-md p-2 flex items-center justify-center`}><GiOrange color='white' size={32} /></div>
                                        </div>
                                        <div className='p-1'>
                                            <div className={`${ch5?.mode?.Forcage === "on" ? "bg-primary" : "bg-lightgray"} rounded-md p-2 flex items-center justify-center text-white font-bold text-2xl`}>F</div>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex justify-between'>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch5?.status?.humsign === "on" ? 'bg-primary' : 'bg-lightgray'} h-14 rounded-md flex items-center justify-center`}><WiHumidity color='white' size={35} /></div>
                                    </div>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch5?.status?.faninsign === "on" ? 'bg-primary' : 'bg-lightgray'} flex flex-col h-14 rounded-md flex items-center justify-center`}>
                                            <PiFanLight color='white' size={35} />
                                            <p className='text-white text-sm'>In</p>
                                        </div>
                                    </div>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch5?.status?.fanoutsign === "on" ? 'bg-primary' : 'bg-lightgray'} flex flex-col h-14 rounded-md flex items-center justify-center`}>
                                            <PiFanLight color='white' size={35} />
                                            <p className='text-white text-sm'>Out</p>
                                        </div>
                                    </div>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch5?.status?.doorsign === "on" ? 'bg-primary' : 'bg-lightgray'} h-14 rounded-md flex items-center justify-center`}>
                                            {
                                                ch5?.status?.doorsign === "on" ? (
                                                    <FaDoorOpen color='white' size={35} />
                                                ) : (
                                                    <FaDoorClosed color='white' size={35} />
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch5?.status?.ethsign === "on" ? 'bg-primary' : 'bg-lightgray'} h-14 rounded-md flex items-center justify-center`}><img src={Eth} alt="" width={30} /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Card 06 */}
                    <div className='w-full lg:w-1/2 py-2 lg:p-2'>
                        <div className='bg-white p-4 rounded-lg shadow-md'>
                            <p className='text-3xl lg:text-4xl font-bold text-center border-b-2 border-primary pb-4'>Chambre 06</p>
                            <div>
                                <div className='mt-4 flex justify-between items-center'>
                                    <div className='w-4/5 pr-4 flex justify-between items-center'>
                                        <div>
                                            <p className='text-sm '>Température</p>
                                            <p className='text-sm mt-4'>Humidité</p>
                                            <p className='text-sm mt-4'>CO<sub>2</sub></p>
                                            <p className='text-sm mt-4'>Éthylène</p>
                                        </div>

                                        <div>
                                            <p className='text-lg lg:text-xl font-semibold'>{ch6?.data?.temp} <span className='text-sm'><sup>o</sup>C</span></p>
                                            <p className='text-lg lg:text-xl font-semibold mt-3'>{ch6?.data?.hum} <span className='text-sm'>%</span></p>
                                            <p className='text-lg lg:text-xl font-semibold mt-3'>{ch6?.data?.co2} <span className='text-sm'>ppm</span></p>
                                            <p className='text-lg lg:text-xl font-semibold mt-3'>{ch6?.data?.eth} <span className='text-sm'>ppm</span></p>
                                        </div>

                                        <div>
                                            <p className='text-sm lg:text-base text-gray '>{ch6?.consignes?.tempconsigne === '-' ? ch6?.consignes?.tempconsigne : ch6?.consignes?.tempconsigne + '*'}</p>
                                            <p className='text-sm lg:text-base text-gray mt-4'>{ch6?.consignes?.humconsigne === '-' ? ch6?.consignes?.humconsigne : ch6?.consignes?.humconsigne + '*'}</p>
                                            <p className='text-sm lg:text-base text-gray mt-4'>{ch6?.consignes?.co2consigne === '-' ? ch6?.consignes?.co2consigne : ch6?.consignes?.co2consigne + '*'}</p>
                                            <p className='text-sm lg:text-base text-gray mt-4'>{ch6?.consignes?.ethconsigne === '-' ? ch6?.consignes?.ethconsigne : ch6?.consignes?.ethconsigne + '*'}</p>
                                        </div>
                                    </div>

                                    <div className='w-1/5'>
                                        <div className='p-1'>
                                            <div className={`${ch6?.mode?.conservsign === "on" ? "bg-primary" : "bg-lightgray"} rounded-md p-2 flex items-center justify-center`}><FaRegSnowflake color='white' size={32} /></div>
                                        </div>
                                        <div className='p-1'>
                                            <div className={`${ch6?.mode?.deversign === "on" ? "bg-primary" : "bg-lightgray"} rounded-md p-2 flex items-center justify-center`}><GiOrange color='white' size={32} /></div>
                                        </div>
                                        <div className='p-1'>
                                            <div className={`${ch6?.mode?.Forcage === "on" ? "bg-primary" : "bg-lightgray"} rounded-md p-2 flex items-center justify-center text-white font-bold text-2xl`}>F</div>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex justify-between'>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch6?.status?.humsign === "on" ? 'bg-primary' : 'bg-lightgray'} h-14 rounded-md flex items-center justify-center`}><WiHumidity color='white' size={35} /></div>
                                    </div>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch6?.status?.faninsign === "on" ? 'bg-primary' : 'bg-lightgray'} flex flex-col h-14 rounded-md flex items-center justify-center`}>
                                            <PiFanLight color='white' size={35} />
                                            <p className='text-white text-sm'>In</p>
                                        </div>
                                    </div>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch6?.status?.fanoutsign === "on" ? 'bg-primary' : 'bg-lightgray'} flex flex-col h-14 rounded-md flex items-center justify-center`}>
                                            <PiFanLight color='white' size={35} />
                                            <p className='text-white text-sm'>Out</p>
                                        </div>
                                    </div>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch6?.status?.doorsign === "on" ? 'bg-primary' : 'bg-lightgray'} h-14 rounded-md flex items-center justify-center`}>
                                            {
                                                ch6?.status?.doorsign === "on" ? (
                                                    <FaDoorOpen color='white' size={35} />
                                                ) : (
                                                    <FaDoorClosed color='white' size={35} />
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch6?.status?.ethsign === "on" ? 'bg-primary' : 'bg-lightgray'} h-14 rounded-md flex items-center justify-center`}><img src={Eth} alt="" width={30} /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Card 07 */}
                    <div className='w-full lg:w-1/2 py-2 lg:p-2'>
                        <div className='bg-white p-4 rounded-lg shadow-md'>
                            <p className='text-3xl lg:text-4xl font-bold text-center border-b-2 border-primary pb-4'>Chambre 07</p>
                            <div>
                                <div className='mt-4 flex justify-between items-center'>
                                    <div className='w-4/5 pr-4 flex justify-between items-center'>
                                        <div>
                                            <p className='text-sm'>Température</p>
                                            <p className='text-sm mt-4'>Humidité</p>
                                            <p className='text-sm mt-4'>CO<sub>2</sub></p>
                                            <p className='text-sm mt-4'>Éthylène</p>
                                        </div>

                                        <div>
                                            <p className='text-lg lg:text-xl font-semibold'>{ch7?.data?.temp} <span className='text-sm'><sup>o</sup>C</span></p>
                                            <p className='text-lg lg:text-xl font-semibold mt-3'>{ch7?.data?.hum} <span className='text-sm'>%</span></p>
                                            <p className='text-lg lg:text-xl font-semibold mt-3'>{ch7?.data?.co2} <span className='text-sm'>ppm</span></p>
                                            <p className='text-lg lg:text-xl font-semibold mt-3'>{ch7?.data?.eth} <span className='text-sm'>ppm</span></p>
                                        </div>

                                        <div>
                                            <p className='text-sm lg:text-base text-gray '>{ch7?.consignes?.tempconsigne === '-' ? ch7?.consignes?.tempconsigne : ch7?.consignes?.tempconsigne + '*'}</p>
                                            <p className='text-sm lg:text-base text-gray mt-4'>{ch7?.consignes?.humconsigne === '-' ? ch7?.consignes?.humconsigne : ch7?.consignes?.humconsigne + '*'}</p>
                                            <p className='text-sm lg:text-base text-gray mt-4'>{ch7?.consignes?.co2consigne === '-' ? ch7?.consignes?.co2consigne : ch7?.consignes?.co2consigne + '*'}</p>
                                            <p className='text-sm lg:text-base text-gray mt-4'>{ch7?.consignes?.ethconsigne === '-' ? ch7?.consignes?.ethconsigne : ch7?.consignes?.ethconsigne + '*'}</p>
                                        </div>
                                    </div>

                                    <div className='w-1/5'>
                                        <div className='p-1'>
                                            <div className={`${ch7?.mode?.conservsign === "on" ? "bg-primary" : "bg-lightgray"} rounded-md p-2 flex items-center justify-center`}><FaRegSnowflake color='white' size={32} /></div>
                                        </div>
                                        <div className='p-1'>
                                            <div className={`${ch7?.mode?.deversign === "on" ? "bg-primary" : "bg-lightgray"} rounded-md p-2 flex items-center justify-center`}><GiOrange color='white' size={32} /></div>
                                        </div>
                                        <div className='p-1'>
                                            <div className={`${ch7?.mode?.Forcage === "on" ? "bg-primary" : "bg-lightgray"} rounded-md p-2 flex items-center justify-center text-white font-bold text-2xl`}>F</div>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex justify-between'>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch7?.status?.humsign === "on" ? 'bg-primary' : 'bg-lightgray'} h-14 rounded-md flex items-center justify-center`}><WiHumidity color='white' size={35} /></div>
                                    </div>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch7?.status?.faninsign === "on" ? 'bg-primary' : 'bg-lightgray'} flex flex-col h-14 rounded-md flex items-center justify-center`}>
                                            <PiFanLight color='white' size={35} />
                                            <p className='text-white text-sm'>In</p>
                                        </div>
                                    </div>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch7?.status?.fanoutsign === "on" ? 'bg-primary' : 'bg-lightgray'} flex flex-col h-14 rounded-md flex items-center justify-center`}>
                                            <PiFanLight color='white' size={35} />
                                            <p className='text-white text-sm'>Out</p>
                                        </div>
                                    </div>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch7?.status?.doorsign === "on" ? 'bg-primary' : 'bg-lightgray'} h-14 rounded-md flex items-center justify-center`}>
                                            {
                                                ch7?.status?.doorsign === "on" ? (
                                                    <FaDoorOpen color='white' size={35} />
                                                ) : (
                                                    <FaDoorClosed color='white' size={35} />
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch7?.status?.ethsign === "on" ? 'bg-primary' : 'bg-lightgray'} h-14 rounded-md flex items-center justify-center`}><img src={Eth} alt="" width={30} /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Card 08 */}
                    <div className='w-full lg:w-1/2 py-2 lg:p-2'>
                        <div className='bg-white p-4 rounded-lg shadow-md'>
                            <p className='text-3xl lg:text-4xl font-bold text-center border-b-2 border-primary pb-4'>Chambre 08</p>
                            <div>
                                <div className='mt-4 flex justify-between items-center'>
                                    <div className='w-4/5 pr-4 flex justify-between items-center'>
                                        <div>
                                            <p className='text-sm '>Température</p>
                                            <p className='text-sm mt-4'>Humidité</p>
                                            <p className='text-sm mt-4'>CO<sub>2</sub></p>
                                            <p className='text-sm mt-4'>Éthylène</p>
                                        </div>

                                        <div>
                                            <p className='text-lg lg:text-xl font-semibold'>{ch8?.data?.temp} <span className='text-sm'><sup>o</sup>C</span></p>
                                            <p className='text-lg lg:text-xl font-semibold mt-3'>{ch8?.data?.hum} <span className='text-sm'>%</span></p>
                                            <p className='text-lg lg:text-xl font-semibold mt-3'>{ch8?.data?.co2} <span className='text-sm'>ppm</span></p>
                                            <p className='text-lg lg:text-xl font-semibold mt-3'>{ch8?.data?.eth} <span className='text-sm'>ppm</span></p>
                                        </div>

                                        <div>
                                            <p className='text-sm lg:text-base text-gray '>{ch8?.consignes?.tempconsigne === '-' ? ch8?.consignes?.tempconsigne : ch8?.consignes?.tempconsigne + '*'}</p>
                                            <p className='text-sm lg:text-base text-gray mt-4'>{ch8?.consignes?.humconsigne === '-' ? ch8?.consignes?.humconsigne : ch8?.consignes?.humconsigne + '*'}</p>
                                            <p className='text-sm lg:text-base text-gray mt-4'>{ch8?.consignes?.co2consigne === '-' ? ch8?.consignes?.co2consigne : ch8?.consignes?.co2consigne + '*'}</p>
                                            <p className='text-sm lg:text-base text-gray mt-4'>{ch8?.consignes?.ethconsigne === '-' ? ch8?.consignes?.ethconsigne : ch8?.consignes?.ethconsigne + '*'}</p>
                                        </div>
                                    </div>

                                    <div className='w-1/5'>
                                        <div className='p-1'>
                                            <div className={`${ch8?.mode?.conservsign === "on" ? "bg-primary" : "bg-lightgray"} rounded-md p-2 flex items-center justify-center`}><FaRegSnowflake color='white' size={32} /></div>
                                        </div>
                                        <div className='p-1'>
                                            <div className={`${ch8?.mode?.deversign === "on" ? "bg-primary" : "bg-lightgray"} rounded-md p-2 flex items-center justify-center`}><GiOrange color='white' size={32} /></div>
                                        </div>
                                        <div className='p-1'>
                                            <div className={`${ch8?.mode?.Forcage === "on" ? "bg-primary" : "bg-lightgray"} rounded-md p-2 flex items-center justify-center text-white font-bold text-2xl`}>F</div>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex justify-between'>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch8?.status?.humsign === "on" ? 'bg-primary' : 'bg-lightgray'} h-14 rounded-md flex items-center justify-center`}><WiHumidity color='white' size={35} /></div>
                                    </div>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch8?.status?.faninsign === "on" ? 'bg-primary' : 'bg-lightgray'} flex flex-col h-14 rounded-md flex items-center justify-center`}>
                                            <PiFanLight color='white' size={35} />
                                            <p className='text-white text-sm'>In</p>
                                        </div>
                                    </div>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch8?.status?.fanoutsign === "on" ? 'bg-primary' : 'bg-lightgray'} flex flex-col h-14 rounded-md flex items-center justify-center`}>
                                            <PiFanLight color='white' size={35} />
                                            <p className='text-white text-sm'>Out</p>
                                        </div>
                                    </div>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch8?.status?.doorsign === "on" ? 'bg-primary' : 'bg-lightgray'} h-14 rounded-md flex items-center justify-center`}>
                                            {
                                                ch8?.status?.doorsign === "on" ? (
                                                    <FaDoorOpen color='white' size={35} />
                                                ) : (
                                                    <FaDoorClosed color='white' size={35} />
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className='p-1 w-1/5'>
                                        <div className={`${ch8?.status?.ethsign === "on" ? 'bg-primary' : 'bg-lightgray'} h-14 rounded-md flex items-center justify-center`}><img src={Eth} alt="" width={30} /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </main>
        </div>
    )
}

export default Chambres;