import React from 'react';

import Logo from '../assets/images/PDever.svg';
import NotFoundImg from '../assets/images/404.svg';

import { Link } from 'react-router-dom';

import { IoIosArrowRoundBack } from "react-icons/io";

const NotFound = () => {
    return (
        <div className='h-screen flex lg:flex-row flex-col justify-evenly items-center px-4'>
            <div className='w-full lg:w-1/2 flex flex-col justify-center items-center '>
                <img src={Logo} alt="" className='mb-8 lg:mb-10 lg:w-[150px] w-[110px]' />
                <p className='text-3xl lg:text-4xl font-bold mb-2'>Page Not Found</p>
                <p className='font-medium text-sm md:text-base'>Désolé, la page que vous recherchez est <u>introuvable</u>.</p>
                <div className='flex items-center justify-start lg:w-[60%] mx-auto'>
                    <Link
                        to={-1}
                        className='text-sm md:text-base border-2 border-primary rounded-md px-4 md:px-5 py-1 md:py-2 flex justify-between items-center mt-5 hover:bg-light transition-all'
                    >
                        <IoIosArrowRoundBack size={34} />
                        <p className='font-medium ml-2'>Retourner</p>
                    </Link>
                </div>
            </div>
            <div className='w-full lg:w-1/2 md:flex md:items-center md:justify-center'>
                <img src={NotFoundImg} alt="" className='w-[800px]' />
            </div>
        </div>
    )
}

export default NotFound;