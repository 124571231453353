import React, { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar';
import { CiSearch } from 'react-icons/ci';

import { getDocs, query, where } from 'firebase/firestore';
import { logsCollection } from '../config/firebaseConfig';

const AlarmesEvents = () => {
    const [selectedChambre, setSelectedChambre] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    // ============================|| Firebase ||============================
    const [data, setData] = useState([]);

    const handleAlarmesEvents = async () => {
        try {
            // Convert YYYY-MM-DD to timestamp
            const convertedDateStart = new Date(startDate).getTime();
            const convertedDateEnd = new Date(endDate).getTime();

            const q = query(
                logsCollection,
                where('date', '>=', convertedDateStart),
                where('date', '<=', convertedDateEnd),
                where('chambre', '==', String(selectedChambre) ? String(selectedChambre) : 'ch1')
            );

            const querySnapshot = await getDocs(q);
            const dataLogs = querySnapshot.docs.map((doc) => doc.data());

            setData(dataLogs);
        } catch (error) {
            console.error('Error getting data within time range:', error);
        }
    };

    // ============================|| Start, End Date ||============================

    useEffect(() => {
        const today = new Date();
        const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);

        const formatDateTime = (date) => {
            const year = date.getFullYear();
            let month = date.getMonth() + 1;
            if (month < 10) {
                month = '0' + month;
            }
            let day = date.getDate();
            if (day < 10) {
                day = '0' + day;
            }
            let hours = date.getHours();
            if (hours < 10) {
                hours = '0' + hours;
            }
            let minutes = date.getMinutes();
            if (minutes < 10) {
                minutes = '0' + minutes;
            }
            return `${year}-${month}-${day}T${hours}:${minutes}`;
        };

        setStartDate(formatDateTime(yesterday));
        setEndDate(formatDateTime(today));
    }, []);

    return (
        <div className="flex lg:gap-5 bg-[#fffcf6]">
            <Sidebar />
            <main className="flex-1 mx-auto py-4 pr-4 h-screen overflow-y-scroll">
                <p className='text-4xl lg:text-5xl font-bold mb-4'>Alarmes & Events</p>

                {/* Options */}
                <div className='flex flex-wrap items-end justify-between'>

                    <div className='w-full lg:w-3/4 flex flex-wrap'>
                        <div className='w-full lg:w-1/3 pr-2'>
                            <p className='font-semibold'>Chambres</p>
                            <select
                                className='w-full border border-gray p-2 rounded-md outline-none pr-16'
                                value={selectedChambre}
                                onChange={(e) => setSelectedChambre(e.target.value)}
                            >
                                <option value='ch1'>Chambre 01</option>
                                <option value='ch2'>Chambre 02</option>
                                <option value='ch3'>Chambre 03</option>
                                <option value='ch4'>Chambre 04</option>
                                <option value='ch5'>Chambre 05</option>
                                <option value='ch6'>Chambre 06</option>
                                <option value='ch7'>Chambre 07</option>
                                <option value='ch8'>Chambre 08</option>
                            </select>
                        </div>

                        <div className='w-full lg:w-1/3 pr-2 mt-3 md:mt-0'>
                            <p className='font-semibold'>Date de début</p>
                            <input
                                type="datetime-local"
                                className='w-full border border-gray p-2 rounded-md outline-none'
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </div>

                        <div className='w-full lg:w-1/3 pr-2 mt-3 md:mt-0'>
                            <p className='font-semibold'>Date de fin</p>
                            <input
                                type="datetime-local"
                                className='w-full border border-gray p-2 rounded-md outline-none'
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </div>
                    </div>

                    {/* Buttons */}
                    <div className='w-full lg:w-1/4 flex flex-wrap items-end justify-end'>
                        <button
                            className='bg-primary rounded-md mr-4 py-2 px-8 mt-4'
                            onClick={() => handleAlarmesEvents()}
                        >
                            <CiSearch size={27} color='white' strokeWidth={1} />
                        </button>
                    </div>

                </div>

                <div className='overflow-y-scroll'>
                    <table className='w-full mt-8 overflow-hidden rounded-t-lg'>
                        <thead>
                            <tr className='bg-primary'>
                                <th className='p-2 text-center font-semibold text-white text-sm lg:text-base'>Temps</th>
                                <th className='p-2 text-center font-semibold text-white text-sm lg:text-base'>Operateur</th>
                                <th className='p-2 text-center font-semibold text-white text-sm lg:text-base'>Type</th>
                                <th className='p-2 text-center font-semibold text-white text-sm lg:text-base'>Message</th>
                            </tr>
                        </thead>

                        {
                            data.length === 0 && (
                                <tbody>
                                    <tr>
                                        <td colSpan={4}>
                                            <p className="text-center text-primary py-4 font-bold underline text-sm lg:text-base">
                                                Il n'y a pas d'alarmes ni d'événements
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            )
                        }

                        {
                            data.length > 0 && (
                                <tbody>
                                    {
                                        data.map((d, index) => (
                                            <tr key={index}>
                                                <td className='text-center px-1 py-2 border border-primary text-sm lg:text-base'>{(new Date(d.date).toLocaleString())}</td>
                                                <td className='text-center px-1 py-2 border border-primary text-sm lg:text-base'>{d.operateur}</td>
                                                <td className='text-center px-1 py-2 border border-primary text-sm lg:text-base'>{d.type}</td>
                                                <td className='text-center px-1 py-2 border border-primary text-sm lg:text-base'>{d.description}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            )
                        }
                    </table>
                </div>
            </main>
        </div >
    )
}

export default AlarmesEvents;