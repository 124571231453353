// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { collection, getFirestore } from "firebase/firestore";
import { getDatabase, ref } from "firebase/database";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBKBLWAz_tgN3LQ_2aAVpoZO82x2BNt2pg",
    authDomain: "pdever-wazo.firebaseapp.com",
    databaseURL: "https://pdever-wazo-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "pdever-wazo",
    storageBucket: "pdever-wazo.appspot.com",
    messagingSenderId: "648547884945",
    appId: "1:648547884945:web:5afc7be664e2ff4cae0430",
    measurementId: "G-2DSR0P4H33"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const realtimeDb = getDatabase(app);
const auth = getAuth();

const dataCollection = collection(db, "Data");
const logsCollection = collection(db, "Logs");
const porteLogsCollection = collection(db, "PorteLogs");

const chambersRef = ref(realtimeDb, 'chambers');
const accessRef = ref(realtimeDb, 'access');

export {
    dataCollection,
    logsCollection,
    porteLogsCollection,
    chambersRef,
    auth,
    accessRef
};