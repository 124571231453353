import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const Co2Chart = ({ co2, annotation }) => {
    const [isChecked, setIsChecked] = useState(true);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const [strokeWidth, setStrokeWidth] = useState(3)
    const currentWidth = window.innerWidth;

    useEffect(() => {
        if(currentWidth <= 500) {
            setStrokeWidth(1);
        };
    }, [])

    let options = {
        series: [
            {
                name: 'CO₂ (ppm)',
                data: co2,
                curve: 'smooth',
            },
        ],
        chart: {
            type: 'line',
            stacked: false,
            height: 600,
            zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true
            },
            toolbar: {
                //autoSelected: 'zoom'
                export: {
                    csv: {
                        filename: undefined,
                        columnDelimiter: ',',
                        headerCategory: 'date',
                        headerValue: 'temp',
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toLocaleString().replace(',', '')
                        }
                    },
                    svg: {
                        filename: undefined,
                    },
                    png: {
                        filename: undefined,
                    }
                },
                autoSelected: 'zoom'
                //
            }
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            colors: ["#BD6024"],
            width: strokeWidth,
        },
        markers: {
            size: 0,
        },
        xaxis: {
            type: 'datetime',
        },
        tooltip: {
            shared: true,
            y: {
                formatter: function (val) {
                    return (val)
                }
            },
            x: {
                formatter: function (val) {
                    var date = new Date(val);
                    return date.toString();
                }
            }
        },
        annotations: {
            xaxis: isChecked ? annotation : [],
        },
    };

    return (
        <div className='bg-white rounded-lg p-4 shadow-md'>
            <div className='flex justify-between items-center mb-4'>
                <p className='text-2xl lg:text-3xl font-bold'>CO<sub>2</sub></p>
                <label className="relative inline-flex items-center cursor-pointer">
                    <input
                        type="checkbox"
                        value=""
                        className="sr-only peer"
                        onChange={handleCheckboxChange}
                        checked={isChecked}
                    />
                    <div className="w-9 lg:w-11 h-5 lg:h-6 bg-lightgray peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-lightgray after:border after:rounded-full after:h-4 lg:after:h-5 after:w-4 lg:after:w-5 after:transition-all peer-checked:bg-primary"></div>
                    <span className="ms-3 text-sm font-medium">Portes</span>
                </label>
            </div>
            <ReactApexChart options={options} series={options.series} type="line" />
        </div>
    )
}

export default Co2Chart;