import { get, onDisconnect, update } from 'firebase/database';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth, accessRef } from './firebaseConfig';

let id = '';
let exist = false;
let user = '';
let stateOfUser = '';

const loginToFirebase = async (mail, pass) => {
    try {
        const userCredential = await signInWithEmailAndPassword(auth, mail, pass);
        user = userCredential.user;
        id = user.uid; // Assign value to the id variable

        // Return true since the login was successful
        return true;
    } catch (error) {
        // console.log('Can\'t login, error: ', error.message);

        // Return false if login fails
        return false;
    }
};

const checkRecieverExist = async (id) => {
    try {
        const snapshot = await get(accessRef);

        if (snapshot.exists()) {
            const data = snapshot.val();

            if (data && typeof data === 'object') {
                const userIds = Object.keys(data);

                for (const userId of userIds) {
                    const status = data[userId];

                    if (id === userId) {
                        exist = true;
                        stateOfUser = status;
                        break;
                    }
                }
            }
        } else {
            // console.log("No data available");
        }
    } catch (error) {
        console.error("Error reading data", error);
    }

    return exist;
};

const updateRecieverStatus = async () => {
    if (exist) {
        const id = localStorage.getItem('id');

        await update(accessRef, { [id]: 'online', });
        onDisconnect(accessRef).update({ [id]: 'offline' });
        
    }
};

export const loginFunctionAndUpdateStatus = async (mail, pass) => {
    // Connect to Firebase
    const check = await loginToFirebase(mail, pass);
    if (!check) {
        return 'out';
    };

    localStorage.setItem('id', id);

    // Check if Reciever exists
    let exist = await checkRecieverExist(id);

    if (stateOfUser === 'online') {
        return 'online';
    }

    // Ensure the update is performed after the asynchronous checkRecieverExist() operation
    if (exist) {
        await updateRecieverStatus('online');
        return 'in';
    } else {
        console.log("you dont have acess !");
        return 'access';
    }
};

export const onRefrech = async (id) => {

    // Check if Reciever exists
    let exist = await checkRecieverExist(id);

    // Ensure the update is performed after the asynchronous checkRecieverExist() operation
    if (exist) {
        await updateRecieverStatus();


    } else {
        console.log("You dont have acess");
    }
};

export const UpdateStatus = async (id) => {
    await update(accessRef, { [id]: "offline" });
}