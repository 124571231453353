import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { NavLink, useLocation, Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'

import { PiHouseLight, PiDoorOpen } from "react-icons/pi";
import { FiBarChart2 } from "react-icons/fi";
import { GoBell } from "react-icons/go";

import { IoIosArrowBack } from 'react-icons/io';
import { IoSettingsOutline } from 'react-icons/io5';
import { MdMenu } from "react-icons/md";
import { AiOutlineLogout } from "react-icons/ai";

import Logo from '../assets/images/PDever.svg';
import { UpdateStatus } from '../config/loginConfig';

const Sidebar = () => {
    const links = [
        { name: 'Chambres', link: '/chambres', icon: <PiHouseLight size={23} className='min-w-max' /> },
        { name: 'Graphiques', link: '/graphiques', icon: <FiBarChart2 size={23} className='min-w-max' /> },
        { name: 'Alarmes & Events', link: '/alarmes&events', icon: <GoBell size={23} className='min-w-max' /> },
        { name: 'Portes', link: '/portes', icon: <PiDoorOpen size={23} className='min-w-max' /> },
        { name: 'Paramètre', link: '/parametres', icon: <IoSettingsOutline size={23} className='min-w-max' />, margin: true },
        { name: 'Se déconnecter', link: '/', icon: <AiOutlineLogout size={23} className='min-w-max' />, onClick: () => removeStorage() },
    ];

    let isTab = useMediaQuery({ query: "(max-width: 768px)" });
    const { pathname } = useLocation();

    const [isOpen, setIsOpen] = useState(isTab ? false : true);

    const Sidebar_animation = isTab
        ? {
            open: {
                x: 0,
                width: "16rem",
                transition: {
                    damping: 40,
                },
            },
            closed: {
                x: -250,
                width: 0,
                transition: {
                    damping: 40,
                    delay: 0.15,
                },
            }
        }
        : {
            open: {
                width: "16rem",
                transition: {
                    damping: 40,
                },
            },
            closed: {
                width: "4rem",
                transition: {
                    damping: 40,
                },
            }
        };

    useEffect(() => {
        if (isTab) {
            // MOBILE
            setIsOpen(false)
        } else {
            // DESKTOP
            setIsOpen(true)
        }
    }, [isTab]);

    // If the pathname changed, close the sidebar
    useEffect(() => {
        isTab && setIsOpen(false)
    }, [pathname]);

    const removeStorage = async () => {
        const id = localStorage.getItem('id');
        await UpdateStatus(id);
        localStorage.removeItem('isAuth');
        localStorage.removeItem('id');
    };

    return (
        <div>
            <div
                onClick={() => setIsOpen(false)}
                className={`md:hidden fixed inset-0 max-h-screen z-[998] bg-black/50 ${isOpen ? 'block' : 'hidden'}`}>
            </div>

            <motion.div
                variants={Sidebar_animation}
                initial={{ x: isTab ? -250 : 0 }}
                animate={isOpen ? 'open' : 'closed'}
                className='bg-white text-gray shadow-xl z-[999] w-[14rem] max-w-[14rem] h-full overflow-hidden md:relative fixed min-h-screen'
            >
                {/* LOGO */}
                {
                    isOpen
                        ? (
                            <div className='font-medium py-5 pb-5 mx-3 flex justify-center'>
                                <Link to='/chambres' className='text-center'>
                                    <img src={Logo} alt="" className='w-[80px]' />
                                </Link>
                            </div>
                        )
                        : (
                            <div className='text-center font-medium py-5 pb-5 mx-3 flex justify-center'>
                                <Link to='/chambres'>
                                    <img src={Logo} alt="" className='w-[80px]' />
                                </Link>
                            </div>
                        )
                }

                {/* Menus */}
                <div className={`flex flex-col h-full`}>
                    {/* First */}
                    <ul className={`whitespace-pre px-2.5 text-[0.9rem] py-5 flex flex-col gap-1 font-medium overflow-x-hidden scrollbar-thin scrollbar-track-white scrollbar-thumb-[#ccc] h-[55%] md:h-[60%]`}>
                        {
                            links?.map((link, i) => (
                                <li key={i}>
                                    <NavLink
                                        to={link?.link}
                                        // className={`${link?.margin && 'border-t border-light mt-10 rounded-none'} p-2.5 flex rounded-md gap-6 items-center md:cursor-pointer duration-300 font-medium`}
                                        className={`${link?.margin && 'border-t border-light mt-10 rounded-none'} grid p-2.5 rounded-md md:cursor-pointer duration-300 font-medium`}
                                        onClick={link?.onClick}
                                    >
                                        <p className='order-first flex'>{link?.icon} <span className='ml-6'>{link?.name}</span></p>
                                        {/* <p></p> */}
                                    </NavLink>
                                </li>
                            ))
                        }
                    </ul>
                </div>

                {/* CLOSE AND OPEN ARROW ICON  */}
                <motion.div
                    animate={
                        isOpen
                            ? {
                                x: 0,
                                y: 0,
                                rotate: 0
                            }
                            : {
                                x: 0,
                                y: 0,
                                rotate: 180
                            }
                    }
                    transition={{
                        duration: 0,
                    }}
                    onClick={() => setIsOpen(!isOpen)}
                    className='absolute w-fit h-fit z-50 right-2 bottom-32 cursor-pointer md:block hidden bg-primary rounded-full p-2 text-white'
                >
                    <IoIosArrowBack size={25} />
                </motion.div>

            </motion.div>

            <div className='m-3 md:hidden' onClick={() => setIsOpen(true)}>
                <MdMenu size={25} />
            </div>
        </div>
    )
}

export default Sidebar;