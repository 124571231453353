import { updatePassword } from "firebase/auth";
import { auth } from "./firebaseConfig";

export const changePassword = (newPassword) => {
    const user = auth.currentUser;

    // Update the user's password
    updatePassword(user, newPassword)
        .then(() => {
            console.log("Password updated successfully!");
        })
        .catch((error) => {
            console.error("Error updating password:", error.message);
        });
}