import React, { useEffect, useState } from 'react';
import { CiSearch } from "react-icons/ci";
import { PiMicrosoftExcelLogo } from "react-icons/pi";

import Sidebar from '../components/Sidebar';
import TempChart from '../components/graphsConfig/TempChart';
import HumChart from '../components/graphsConfig/HumChart';
import Co2Chart from '../components/graphsConfig/Co2Chart';
import EhtChart from '../components/graphsConfig/EhtChart';

import { getDocs, query, where } from 'firebase/firestore';
import { dataCollection, porteLogsCollection } from '../config/firebaseConfig';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Graphiques = () => {
    const [selectedChambre, setSelectedChambre] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [co2, setCo2] = useState([]);
    const [ethylene, setEthylene] = useState([]);
    const [humidite, setHumidite] = useState([]);
    const [temperature, setTemperature] = useState([]);
    const [time, setTime] = useState([]);
    const [annotation, setAnnotation] = useState([]);
    // Export Data :
    const [co2Exp, setCo2Exp] = useState([]);
    const [ethyleneExp, setEthyleneExp] = useState([]);
    const [humiditeExp, setHumiditeExp] = useState([]);
    const [temperatureExp, setTemperatureExp] = useState([]);

    const handleChartsData = async () => {
        try {
            const convertedDateStart = new Date(startDate).getTime();
            const convertedDateEnd = new Date(endDate).getTime();

            let timeArr = [];
            let co2CSV = [];
            let humiditeCSV = [];
            let ethyleneCSV = [];
            let temperatureCSV = [];

            let co2Arr = [];
            let humiditeArr = [];
            let ethyleneArr = [];
            let temperatureArr = [];

            let portsTimeArr = [];
            let portsOnOffArr = [];

            const q = query(
                dataCollection,
                where('time', '>=', Number(convertedDateStart)),
                where('time', '<=', Number(convertedDateEnd)),
                where('chambre', '==', String(selectedChambre) ? String(selectedChambre) : 'ch1')
            );

            const ports = query(
                porteLogsCollection,
                where('date', '>=', Number(convertedDateStart)),
                where('date', '<=', Number(convertedDateEnd)),
                where('chambre', '==', String(selectedChambre) ? String(selectedChambre) : 'ch1')
            );

            const querySnapshot = await getDocs(q);
            const data = querySnapshot.docs.map((doc) => doc.data());

            const querySnapshotPorts = await getDocs(ports);
            const portsData = querySnapshotPorts.docs.map((doc) => doc.data());

            for (let n = 0; n < data.length; n++) {
                // =======================|| Export Data ||=======================
                timeArr.push((new Date(data[n].time).getMonth() + 1) + '/' + new Date(data[n].time).getDate() + ', ' + ('0' + new Date(data[n].time).getHours()).slice(-2) + ':' + ('0' + new Date(data[n].time).getMinutes()).slice(-2)); // Format date from '11/14/2023, 10:30:12 AM' to '11/14, 10:30'
                co2CSV.push(data[n].co2);
                humiditeCSV.push(data[n].humidite);
                ethyleneCSV.push(data[n].ethylene);
                temperatureCSV.push(data[n].temperature);
                // =======================|| Charts Data ||=======================
                co2Arr.push([data[n].time, data[n].co2]);
                humiditeArr.push([data[n].time, data[n].humidite]);
                ethyleneArr.push([data[n].time, data[n].ethylene]);
                temperatureArr.push([data[n].time, data[n].temperature]);
            };

            for (let n = 0; n < portsData.length; n++) {
                // portsTimeArr.push(portsData[n].date);
                portsOnOffArr.push([portsData[n].date, portsData[n].status]);
            };

            const result = {
                co2: co2Arr,
                time: timeArr,
                humidite: humiditeArr,
                ethylene: ethyleneArr,
                temperature: temperatureArr,
                // Ports Data :
                portsTime: portsTimeArr,
                portsOnOff: portsOnOffArr,
                // Export Data :
                co2Export: co2CSV,
                tempExport: temperatureCSV,
                ethExport: ethyleneCSV,
                humExport: humiditeCSV,
            };

            let newArr = [];
            try {
                if (result.portsOnOff[0][1] === 'off') {
                    result.portsOnOff.shift();
                }
                if (result.portsOnOff.length % 2 !== 0) {
                    result.portsOnOff.pop();
                }
                for (let i = 2; i <= result.portsOnOff.length; i += 2) {
                    let obj = {
                        x: result.portsOnOff[i - 2][0],
                        x2: result.portsOnOff[i - 1][0],
                        fillColor: '#F39C12',
                        label: {
                            text: 'Porte ouverte'
                        }
                    };
                    newArr.push(obj);
                }
            } catch (error) {
                console.log(error);
            }

            setTime(result.time);
            setCo2(result.co2);
            setEthylene(result.ethylene);
            setHumidite(result.humidite);
            setTemperature(result.temperature);
            setAnnotation(newArr);
            // Export Data :
            setCo2Exp(result.co2Export);
            setEthyleneExp(result.ethExport);
            setHumiditeExp(result.humExport);
            setTemperatureExp(result.tempExport);
        } catch (error) {
            console.error('Error getting data within time range:', error);
        }
    };

    useEffect(() => {
        const today = new Date();
        const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);

        const formatDateTime = (date) => {
            const year = date.getFullYear();
            let month = date.getMonth() + 1;
            if (month < 10) {
                month = '0' + month;
            }
            let day = date.getDate();
            if (day < 10) {
                day = '0' + day;
            }
            let hours = date.getHours();
            if (hours < 10) {
                hours = '0' + hours;
            }
            let minutes = date.getMinutes();
            if (minutes < 10) {
                minutes = '0' + minutes;
            }
            return `${year}-${month}-${day}T${hours}:${minutes}`;
        };

        setStartDate(formatDateTime(yesterday));
        setEndDate(formatDateTime(today));
    }, []);

    // ==========================| Notify Toast |========================== //

    const notifySuccess = () => toast.success('Les données téléchargées avec succès', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });

    const notifyInfo = () => toast.info('Il n\'y a pas de données!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });

    // ====================================================================

    // ||============================|| Data Exportation To CSV ||============================||

    const dataExport = () => {
        let result = `Time, Temperature, Humidite, CO2, Ethylene\n`;
        const dataLenght = time.length;
        for (let i = 0; i < dataLenght; i++) {
            result += `${time[i].replace(',', ' ')}, ${temperatureExp[i]}, ${humiditeExp[i]}, ${co2Exp[i]}, ${ethyleneExp[i]},\n`;
        }
        return result;
    }

    // domaine quoaliagro marrakech
    async function exportToExcel() {
        if (time.length > 0) {
            const fileData = await dataExport();
            const blob = new Blob([fileData], { type: "text/plain" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            const date = new Date();

            let name;
            if (selectedChambre === 'ch1') {
                name = 'Chambre 01';
            } else if (selectedChambre === 'ch2') {
                name = 'Chambre 02';
            } else if (selectedChambre === 'ch3') {
                name = 'Chambre 03';
            } else if (selectedChambre === 'ch4') {
                name = 'Chambre 04';
            } else if (selectedChambre === 'ch5') {
                name = 'Chambre 05';
            } else if (selectedChambre === 'ch6') {
                name = 'Chambre 06';
            } else if (selectedChambre === 'ch7') {
                name = 'Chambre 07';
            } else if (selectedChambre === 'ch8') {
                name = 'Chambre 08';
            } else {
                name = 'Chmabre 01'
            }

            link.download = `PDever-Wazo-(${name})-${date.toLocaleDateString()}.csv`;
            link.href = url;
            link.click();
            notifySuccess();
        } else {
            console.error('Time array is empty or undefined.');
            notifyInfo();
        }
    }

    // ====================================================================

    return (
        <div className="flex lg:gap-5 bg-[#fffcf6]">
            <Sidebar />
            <main className="flex-1 mx-auto py-4 pr-4 h-screen overflow-y-scroll">

                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    limit={1}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover={false}
                    theme="colored"
                />
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    limit={1}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover={false}
                    theme="colored"
                />

                <p className='text-4xl lg:text-5xl font-bold mb-4'>Graphiques</p>

                {/* Options */}
                <div className='flex flex-wrap items-end justify-between'>

                    <div className='w-full lg:w-3/4 flex flex-wrap'>
                        <div className='w-full lg:w-1/3 pr-2'>
                            <p className='font-semibold'>Chambres</p>
                            <select
                                className='w-full border border-gray p-2 rounded-md outline-none pr-16'
                                value={selectedChambre}
                                onChange={(e) => setSelectedChambre(e.target.value)}
                            >
                                <option value='ch1'>Chambre 01</option>
                                <option value='ch2'>Chambre 02</option>
                                <option value='ch3'>Chambre 03</option>
                                <option value='ch4'>Chambre 04</option>
                                <option value='ch5'>Chambre 05</option>
                                <option value='ch6'>Chambre 06</option>
                                <option value='ch7'>Chambre 07</option>
                                <option value='ch8'>Chambre 08</option>
                            </select>
                        </div>

                        <div className='w-full lg:w-1/3 pr-2 mt-3 md:mt-0'>
                            <p className='font-semibold'>Date de début</p>
                            <input
                                type="datetime-local"
                                className='w-full border border-gray p-2 rounded-md outline-none'
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </div>

                        <div className='w-full lg:w-1/3 pr-2 mt-3 md:mt-0'>
                            <p className='font-semibold'>Date de fin</p>
                            <input
                                type="datetime-local"
                                className='w-full border border-gray p-2 rounded-md outline-none'
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </div>
                    </div>

                    {/* Buttons */}
                    <div className='w-full lg:w-1/4 flex flex-wrap items-end justify-end'>
                        <button
                            className='bg-primary rounded-md mr-4 py-2 px-8 mt-4'
                            onClick={() => handleChartsData()}
                        >
                            <CiSearch size={27} color='white' strokeWidth={1} />
                        </button>

                        <button
                            className='bg-primary rounded-md mr-4 py-2 px-8 mt-4'
                            onClick={() => exportToExcel()}
                        >
                            <PiMicrosoftExcelLogo size={27} color='white' strokeWidth={1} />
                        </button>
                    </div>

                </div>

                {/* Charts */}
                <div className='mt-8'>
                    <div className='flex flex-wrap justify-between'>
                        <div className='w-full lg:w-1/2 p-1'>
                            <TempChart temperature={temperature} annotation={annotation} />
                        </div>
                        <div className='w-full lg:w-1/2 p-1'>
                            <HumChart humidite={humidite} annotation={annotation} />
                        </div>
                    </div>
                    <div className='flex flex-wrap justify-between'>
                        <div className='w-full lg:w-1/2 p-1'>
                            <Co2Chart co2={co2} annotation={annotation} />
                        </div>
                        <div className='w-full lg:w-1/2 p-1'>
                            <EhtChart ethylene={ethylene} annotation={annotation} />
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default Graphiques;